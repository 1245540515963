@import "https://use.fontawesome.com/ede1f9233a.css";
@import "_variables";
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);


// General
body{background: #e6e2e2 url(/img/7/vwas-bg.jpg);}

body.detail {
  main {
    // prevent double padding
    section > section {
      padding: 0.5rem 0;
    }
  }
}
.slider.variant1 .slide .caption{
  border-radius: 5px;
}
.header.variant3 .top-bar-section{
  margin: 1.3125rem 1.25rem 0;
}

body {
  #fontSize {
    float: right;
    line-height: 2.5;
    span {
      padding: 0 5px;
      cursor:pointer;
      &:hover {
        color:#bd8b40;
      }
    }
  }
  &.small {
    main {
      p, span, strong, i, em, a, span a {
        font-size:12px;
      }
    }
  }
  &.regular {
    main {
      p, span, strong, i, em, a, span a {
        font-size: 16px;
      }
    }
  }
  &.large {
    main {
      p, span, strong, i, em, a, span a {
        font-size: 32px;
      }
    }
  }
}

.input_container,
.label_container {
  float:left;
  width: 50%;
}

.header.variant3 .topWrapper {
  display: block !important
}


// Media Queries
@media #{$large-up} {

}
@media #{$medium-up}{

}
@media #{$medium-down} {

}

// prevent two line menu
@media only screen and (max-width: 1350px) {
  .header.variant3 nav.top-bar-section, .right {
    //display:none;
  }
  .toggle-topbar.menu-icon {
    display:block;
  }
}